@use '../global';
@use 'sass:math';

$circle-diameter: 2.25rem;
$l-r-padding: 1rem;
$max-width: 80rem;

.get-involved {
  background-color: global.$brand-yellow;
  margin-top: -29vw;
  padding-top: 60vw;
  @include global.center-column;
  overflow: hidden;
}

.get-involved::after {
  @include global.jagged-edge(
    30vw,
    '../assets/svg/mob-shape3.svg',
    '../assets/svg/shape3.svg',
    26vw
  );
}

.get-involved__heading {
  padding: 0 $l-r-padding;
  width: 100%;
  max-width: math.div($max-width, 2);

  @media screen and (min-width: global.$breakpoint-m) {
    padding: 0 10%;
  }
}

.get-involved__heading.heading--small-turbo {
  padding: 0;
  width: inherit;
  max-width: none;
}

.get-involved__list {
  display: grid;
  grid-template-columns: 1fr;
  padding: $l-r-padding;
  counter-reset: item;
  max-width: math.div($max-width, 2);

  @media screen and (min-width: global.$breakpoint-l) {
    grid-template-columns: 1fr 1fr;
    padding: 4rem 10%;
    column-gap: 20%;
    max-width: $max-width;
  }
}

.get-involved__item {
  margin: 2.5rem 0;
}

.get-involved__item h3 {
  text-align: left;
}

.get-involved__item p {
  font-size: 1rem;
}

.get-involved__item::before {
  counter-increment: item;
  content: counter(item);
  background-color: black;
  color: global.$brand-yellow;
  display: block;
  width: $circle-diameter;
  height: $circle-diameter;
  border-radius: $circle-diameter * 0.5;
  text-align: center;
  line-height: $circle-diameter;
  font-family: 'BatonTurbo', Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
}

.get-involved__logo-marquee {
  padding: 2rem 0;
  width: max-content;
  display: flex;
  @media screen and (min-width: global.$breakpoint-l) {
    padding: 2rem 0 6rem 0;
  }
}

.get-involved__logo-marquee-container {
  width: max-content;
  display: flex;
}

.get-involved__logo {
  width: 8rem;
  max-height: 4rem;
  object-fit: contain;
  display: inline-block;
  margin: 0 4rem;
}
