@use '../global';

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  overflow-x: hidden;
}

body {
  font-family: 'BatonTurbo', Arial, Helvetica, sans-serif;
  font-weight: 400;
  background-color: global.$beige-bg;
  overflow-x: hidden;
  font-size: 1.125rem;

  @media screen and (min-width: global.$breakpoint-s) {
    font-size: 1.25rem;
  }

  @media screen and (min-width: global.$breakpoint-m) {
    font-size: 1.75rem;
  }
}

p {
  margin: 1rem 0;
}
