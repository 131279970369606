@use '../global';

.splash {
  position: relative;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  width: 100%;
  height: 85vh;
  background-color: global.$beige-bg;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  pointer-events: none;

  @media screen and (min-width: global.$breakpoint-l) {
    height: 100vh;
  }
}

.splash__logo {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  padding: 5%;
  width: 100%;
  max-height: 100%;
}

.splash__scroll {
  position: absolute;
  width: 100%;
  bottom: -10%;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: global.$breakpoint-l) {
    bottom: 1rem;
  }
}

.splash__line {
  width: 1px;
  height: 30px;
  background-color: black;
}

.splash__text {
  @include global.baton-bold;
  text-transform: uppercase;
  font-size: 1.5rem;
}

.splash__title {
  @include global.visually-hidden;
}
