@use '../global';

$photo-small-skew: 7deg;
$photo-large-skew: 3deg;
$photo-offset-x: -34%;
$photo-offset-y: 10%;
$photo-offset-compression-x: 1.4;
$photo-offset-compression-y: 0.5;
$photo-stack-padding: 9%;

.photos::after {
  @include global.jagged-edge(
    30vw,
    '../assets/svg/mob-shape2.svg',
    '../assets/svg/shape2.svg',
    30vw
  );
}

.photos__container {
  width: 100%;
  margin: 4rem 0;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
  flex-direction: column;

  @media screen and (min-width: global.$breakpoint-l) {
    flex-direction: row;
  }

  &--flex-row {
    flex-direction: row;
  }
}

.photos__container:last-child {
  margin-bottom: -54vw;
}

.photos__card-stack {
  width: 100vw;
  display: flex;
  justify-content: center;

  @media screen and (min-width: global.$breakpoint-l) {
    width: 50vw;
  }
}

.photos__card-stack--left {
  justify-content: flex-start;
  padding-left: 1rem;

  @media screen and (min-width: global.$breakpoint-l) {
    padding-left: $photo-stack-padding;
  }
}
.photos__card-stack--right {
  justify-content: center;
  padding-right: 1rem;

  @media screen and (min-width: global.$breakpoint-l) {
    justify-content: flex-end;
    padding-right: $photo-stack-padding;
  }
}

.photos__card {
  overflow: hidden;
  display: flex;
  align-items: center;
}

.photos__card--small {
  width: 50vw;
  height: 65vw;

  @media screen and (min-width: global.$breakpoint-l) {
    width: 24vw;
    height: 33.6vw;
  }

  transform: skewY($photo-small-skew);
}

.photos__card--large {
  transform: skewY($photo-large-skew);
  padding: 0 1rem;
  flex-grow: 1;
  justify-content: center;
}

.photos__card img {
  object-fit: cover;
  object-position: center;
}

.photos__card--small img {
  width: 100%;
  height: 110%;
  transform: skewY($photo-small-skew * -1);
}

.photos__card--large img {
  width: 100%;
  max-width: 100rem;
  height: 110%;
  transform: skewY($photo-large-skew * -1);
}

.photos__card--left:nth-last-child(1) {
  margin: 0 $photo-offset-x 0 0;
}
.photos__card--left:nth-last-child(2) {
  margin: $photo-offset-y $photo-offset-x $photo-offset-y 0;
}

.photos__card--left:nth-last-child(3) {
  margin: $photo-offset-y * 2 $photo-offset-x $photo-offset-y * 2 0;
}

.photos__card--right-compressed:nth-last-child(1) {
  margin: 0 0 0 $photo-offset-x * $photo-offset-compression-x;
}
.photos__card--right-compressed:nth-last-child(2) {
  // this centers the images on mobile
  margin: $photo-offset-y * $photo-offset-compression-y 0 $photo-offset-y *
    $photo-offset-compression-y 0;

  @media screen and (min-width: global.$breakpoint-l) {
    margin: $photo-offset-y * $photo-offset-compression-y 0 $photo-offset-y *
      $photo-offset-compression-y $photo-offset-x * $photo-offset-compression-x;
  }
}
