@font-face {
  font-family: Baton;
  src: url("BatonWeb-Bold.3898cd0b.woff2") format("woff2"), url("BatonWeb-Bold.2fdbe94a.woff") format("woff");
  font-weight: bold;
}

@font-face {
  font-family: BatonTurbo;
  src: url("BatonTurboWeb-Regular.31fc28ec.woff2") format("woff2"), url("BatonTurboWeb-Regular.0c6896ff.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: BatonTurbo;
  src: url("BatonTurboWeb-Medium.817f6486.woff2") format("woff2"), url("BatonTurboWeb-Medium.dc3d13f3.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: BatonTurbo;
  src: url("BatonTurboWeb-BoldItalic.80604fdd.woff2") format("woff2"), url("BatonTurboWeb-BoldItalic.f05e5b66.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: BatonTurbo;
  src: url("BatonTurboWeb-Bold.4b271cbc.woff2") format("woff2"), url("BatonTurboWeb-Bold.0f2418c6.woff") format("woff");
  font-weight: 700;
}

.hidden {
  opacity: 0;
}

.overflow-hidden {
  overflow: hidden;
}

.display-none {
  display: none;
}

.button:not(.button:last-child) {
  margin-right: .25rem;
}

.button:not(.button:first-child) {
  margin-left: .25rem;
}

.button {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #f0ff69;
  cursor: pointer;
  background-color: #000;
  border: none;
  max-width: 95vw;
  margin: 1rem 0;
  padding: .6rem 2.5rem;
  font-family: Baton, Arial, Helvetica, sans-serif;
  font-size: 1.25rem;
  font-weight: bold;
  transition: transform .4s ease-in-out;
  display: inline-block;
  transform: skewY(2deg);
}

.button:hover {
  transform: skewY(-2deg);
}

.button:hover .button__text {
  transform: skewY(2deg);
}

.button--straight {
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 5px;
  max-width: 95vw;
  margin: 0;
  padding: 1.5rem 1rem;
  font-family: BatonTurbo, Arial, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  transform: none;
}

.button--small {
  margin: 0;
  padding: .6rem;
  transform: skewY(5deg);
}

@media screen and (width >= 480px) {
  .button--small {
    padding: 1.25rem;
  }
}

.button__text {
  transition: transform .4s ease-in-out;
  display: inline-block;
  transform: skewY(-2deg);
}

.button--small .button__text {
  transform: skewY(-5deg);
}

.heading {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: BatonTurbo, Arial, Helvetica, sans-serif;
  font-size: 2.8em;
  font-style: italic;
  font-weight: 700;
  line-height: 1;
}

.heading span {
  display: block;
}

.heading--small {
  letter-spacing: 1px;
  margin: 1rem;
  font-family: Baton, Arial, Helvetica, sans-serif;
  font-size: 1.5em;
  font-style: normal;
  font-weight: bold;
}

@media screen and (width >= 900px) {
  .heading--small {
    font-size: 1.125em;
  }
}

.heading--small-turbo {
  text-align: center;
  margin: 1rem 0;
  font-size: 1.5em;
  font-style: normal;
}

@media screen and (width >= 900px) {
  .heading--small-turbo {
    font-size: 1.125em;
  }
}

.heading span:nth-child(2) {
  margin-left: .7em;
}

.heading span:nth-child(3), .heading--extreme h1:nth-child(2) {
  margin-left: 1.4em;
}

.heading--extreme h1:nth-child(3) {
  margin-left: 2.8em;
}

.modal {
  z-index: 100;
  border: none;
  width: 100vw;
  height: 100vh;
  display: none;
  position: fixed;
  top: 0;
}

.modal.visible {
  display: block;
}

.modal__form.display-none {
  display: none;
}

.modal__container {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
}

.modal__heading {
  font-size: 11vw;
}

@media screen and (width >= 0) {
  .modal__heading {
    font-size: 2rem;
  }
}

@media screen and (width >= 0) and (height >= 650px) {
  .modal__heading {
    font-size: 2.8rem;
  }
}

.modal__inner {
  z-index: 1;
  background-color: #f0ff69;
  border-radius: 5px;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  justify-items: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: grid;
  position: relative;
  overflow: scroll;
}

.modal__overlay {
  opacity: .3;
  background-color: #000;
  width: 100%;
  height: 100%;
  position: fixed;
}

.modal__form {
  letter-spacing: 1px;
  text-transform: uppercase;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 30rem;
  margin: 2rem 0;
  font-family: BatonTurbo, Arial, Helvetica, sans-serif;
  font-weight: 700;
  line-height: 1;
  display: flex;
}

.modal__form label {
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  white-space: nowrap;
  border: 0;
  width: 1px;
  height: 1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.modal__form input, .modal__form textarea {
  resize: none;
  cursor: pointer;
  -webkit-appearance: none;
  letter-spacing: 1px;
  background-color: #0000;
  border: none;
  border-radius: 5px;
  width: 100%;
  margin: .3rem 0;
  padding: 0 2rem;
  font-family: BatonTurbo, Arial, Helvetica, sans-serif;
  font-weight: 700;
  line-height: 1;
  box-shadow: 0 0 0 1px #000;
}

.modal__form input {
  height: 65px;
}

.modal__form textarea {
  height: 111px;
}

.modal__form input:focus, .modal__form textarea:focus {
  background-color: #fff;
  outline: none;
  box-shadow: 0 0 0 2px #000;
}

.modal__form input.activated:invalid:not(:focus), .modal__form textarea.activated:invalid:not(:focus) {
  color: gray;
  background-color: #fff;
  box-shadow: 0 0 0 2px red;
}

.modal__form input.activated:not(:focus)::placeholder, .modal__form textarea.activated:not(:focus)::placeholder {
  color: red;
  opacity: .5;
}

.modal__form textarea {
  padding: 1rem 2rem;
}

@media screen and (height >= 650px) {
  .modal__form textarea {
    padding: 1rem 2rem;
  }
}

.modal__form input::placeholder, .modal__form textarea::placeholder {
  color: #000;
  opacity: .5;
}

.modal__submit {
  width: 100%;
  margin: 5% 0 0;
}

@media screen and (height >= 650px) {
  .modal__submit {
    margin: 2rem 0;
  }
}

.modal__close {
  cursor: pointer;
  background: url("close.164789c4.svg") center / cover no-repeat;
  border: none;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.modal__error {
  color: red;
  width: 100%;
  height: .6rem;
  margin: .3rem 0;
  font-size: .6rem;
}

input:focus + span.modal__error {
  visibility: hidden;
}

.modal__thank-you {
  text-align: center;
  flex-direction: column;
  grid-row: span 2;
  justify-content: center;
  align-items: center;
  display: flex;
}

.modal__done {
  width: 100%;
}

.modal__text {
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 2.5rem 0;
  font-family: BatonTurbo, Arial, Helvetica, sans-serif;
  font-size: .75rem;
  font-weight: 700;
  line-height: 1;
}

.text-block {
  text-align: center;
  padding: 0 4%;
}

@media screen and (width >= 1300px) {
  .text-block {
    margin: 2rem 0;
  }
}

@media screen and (width >= 650px) {
  .text-block {
    padding: 0 15%;
  }
}

@media screen and (width >= 900px) {
  .text-block {
    padding: 0 20%;
  }
}

@media screen and (width >= 1300px) {
  .text-block {
    padding: 0 30%;
  }
}

*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  overflow-x: hidden;
}

body {
  background-color: #dcd5ca;
  font-family: BatonTurbo, Arial, Helvetica, sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  overflow-x: hidden;
}

@media screen and (width >= 480px) {
  body {
    font-size: 1.25rem;
  }
}

@media screen and (width >= 650px) {
  body {
    font-size: 1.75rem;
  }
}

p {
  margin: 1rem 0;
}

.header {
  z-index: 2;
  justify-content: space-between;
  align-items: flex-start;
  width: 100vw;
  display: flex;
}

@media screen and (width >= 650px) {
  .header {
    position: fixed;
  }
}

.header__buttons {
  opacity: 0;
  pointer-events: none;
  z-index: 2;
  justify-content: flex-end;
  align-items: flex-start;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  transform: translateY(-18%);
}

@media screen and (width >= 650px) {
  .header__buttons {
    position: relative;
  }
}

.header__circle {
  width: 2.25rem;
  margin: .5rem 0 0 1rem;
  display: none;
}

@media screen and (width >= 480px) {
  .header__circle {
    margin: .5rem 0 0 2rem;
  }
}

@media screen and (width >= 650px) {
  .header__circle {
    display: block;
  }
}

.header__progress {
  z-index: 1;
  transform-origin: 0 0;
  background-color: #f0ff69;
  width: 100%;
  height: 4px;
  animation: 8s forwards loading;
  position: absolute;
  top: 0;
  transform: scaleX(.01);
}

@keyframes loading {
  from {
    transform: scaleX(.01);
  }

  to {
    transform: scaleX(.8);
  }
}

@keyframes loaded {
  from {
    transform: scale(.8, 1);
  }

  to {
    transform: scaleY(1, 0);
  }
}

@keyframes disappear {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.header__progress.header__progress--loaded {
  animation: 1s loaded, 1s .5s forwards disappear;
}

.splash {
  text-transform: uppercase;
  z-index: 10;
  pointer-events: none;
  background-color: #dcd5ca;
  align-items: center;
  width: 100%;
  height: 85vh;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (width >= 900px) {
  .splash {
    height: 100vh;
  }
}

.splash__logo {
  width: 100%;
  max-height: 100%;
  padding: 5%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.splash__scroll {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
  position: absolute;
  bottom: -10%;
  left: 0;
}

@media screen and (width >= 900px) {
  .splash__scroll {
    bottom: 1rem;
  }
}

.splash__line {
  background-color: #000;
  width: 1px;
  height: 30px;
}

.splash__text {
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: Baton, Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
}

.splash__title {
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  white-space: nowrap;
  border: 0;
  width: 1px;
  height: 1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.intro {
  background-image: url("shape1_mobile.e6e3c666.svg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
  justify-content: center;
  align-items: center;
  min-height: 160vw;
  margin-top: 50vh;
  display: flex;
}

@media screen and (width >= 480px) {
  .intro {
    background-position: 50% 25%;
  }
}

@media screen and (width >= 900px) {
  .intro {
    background-image: url("shape1.b0a5af50.svg");
    background-position: center;
    background-size: cover;
    min-height: 70vw;
    margin-top: 25vh;
  }
}

@media screen and (width >= 1300px) {
  .intro {
    min-height: 60vw;
  }
}

.photos:after {
  content: "";
  background: url("mob-shape2.3d9e44c6.svg") center / cover no-repeat;
  width: 100%;
  height: 30vw;
  display: block;
  position: relative;
  top: 2px;
}

@media screen and (width >= 900px) {
  .photos:after {
    background-image: url("shape2.4638b46b.svg");
    height: 30vw;
  }
}

.photos__container {
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 4rem 0;
  display: flex;
  position: relative;
}

@media screen and (width >= 900px) {
  .photos__container {
    flex-direction: row;
  }
}

.photos__container--flex-row {
  flex-direction: row;
}

.photos__container:last-child {
  margin-bottom: -54vw;
}

.photos__card-stack {
  justify-content: center;
  width: 100vw;
  display: flex;
}

@media screen and (width >= 900px) {
  .photos__card-stack {
    width: 50vw;
  }
}

.photos__card-stack--left {
  justify-content: flex-start;
  padding-left: 1rem;
}

@media screen and (width >= 900px) {
  .photos__card-stack--left {
    padding-left: 9%;
  }
}

.photos__card-stack--right {
  justify-content: center;
  padding-right: 1rem;
}

@media screen and (width >= 900px) {
  .photos__card-stack--right {
    justify-content: flex-end;
    padding-right: 9%;
  }
}

.photos__card {
  align-items: center;
  display: flex;
  overflow: hidden;
}

.photos__card--small {
  width: 50vw;
  height: 65vw;
  transform: skewY(7deg);
}

@media screen and (width >= 900px) {
  .photos__card--small {
    width: 24vw;
    height: 33.6vw;
  }
}

.photos__card--large {
  flex-grow: 1;
  justify-content: center;
  padding: 0 1rem;
  transform: skewY(3deg);
}

.photos__card img {
  object-fit: cover;
  object-position: center;
}

.photos__card--small img {
  width: 100%;
  height: 110%;
  transform: skewY(-7deg);
}

.photos__card--large img {
  width: 100%;
  max-width: 100rem;
  height: 110%;
  transform: skewY(-3deg);
}

.photos__card--left:last-child {
  margin: 0 -34% 0 0;
}

.photos__card--left:nth-last-child(2) {
  margin: 10% -34% 10% 0;
}

.photos__card--left:nth-last-child(3) {
  margin: 20% -34% 20% 0;
}

.photos__card--right-compressed:last-child {
  margin: 0 0 0 -47.6%;
}

.photos__card--right-compressed:nth-last-child(2) {
  margin: 5% 0;
}

@media screen and (width >= 900px) {
  .photos__card--right-compressed:nth-last-child(2) {
    margin: 5% 0 5% -47.6%;
  }
}

.get-involved {
  background-color: #f0ff69;
  flex-direction: column;
  align-items: center;
  margin-top: -29vw;
  padding-top: 60vw;
  display: flex;
  overflow: hidden;
}

.get-involved:after {
  content: "";
  background: url("mob-shape3.533f7b97.svg") center / cover no-repeat;
  width: 100%;
  height: 30vw;
  display: block;
  position: relative;
  top: 2px;
}

@media screen and (width >= 900px) {
  .get-involved:after {
    background-image: url("shape3.6eaf14b3.svg");
    height: 26vw;
  }
}

.get-involved__heading {
  width: 100%;
  max-width: 40rem;
  padding: 0 1rem;
}

@media screen and (width >= 650px) {
  .get-involved__heading {
    padding: 0 10%;
  }
}

.get-involved__heading.heading--small-turbo {
  width: inherit;
  max-width: none;
  padding: 0;
}

.get-involved__list {
  counter-reset: item;
  grid-template-columns: 1fr;
  max-width: 40rem;
  padding: 1rem;
  display: grid;
}

@media screen and (width >= 900px) {
  .get-involved__list {
    grid-template-columns: 1fr 1fr;
    column-gap: 20%;
    max-width: 80rem;
    padding: 4rem 10%;
  }
}

.get-involved__item {
  margin: 2.5rem 0;
}

.get-involved__item h3 {
  text-align: left;
}

.get-involved__item p {
  font-size: 1rem;
}

.get-involved__item:before {
  counter-increment: item;
  content: counter(item);
  color: #f0ff69;
  text-align: center;
  background-color: #000;
  border-radius: 1.125rem;
  width: 2.25rem;
  height: 2.25rem;
  font-family: BatonTurbo, Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.25rem;
  display: block;
}

.get-involved__logo-marquee {
  width: max-content;
  padding: 2rem 0;
  display: flex;
}

@media screen and (width >= 900px) {
  .get-involved__logo-marquee {
    padding: 2rem 0 6rem;
  }
}

.get-involved__logo-marquee-container {
  width: max-content;
  display: flex;
}

.get-involved__logo {
  object-fit: contain;
  width: 8rem;
  max-height: 4rem;
  margin: 0 4rem;
  display: inline-block;
}

.help {
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  display: flex;
}

.help:after {
  content: "";
  background: url("mob-shape4.2c2651a4.svg") center / cover no-repeat;
  width: 100%;
  height: 34vw;
  display: block;
  position: relative;
  top: 2px;
}

@media screen and (width >= 900px) {
  .help:after {
    background-image: url("shape4.01e2e78d.svg");
    height: 27.8vw;
  }
}

.help__heading {
  padding-bottom: 2rem;
}

.help__buttons {
  text-align: center;
  padding: 0 1rem;
}

.about {
  background-color: #f0ff69;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.about:after {
  content: "";
  background: url("mob-shape5.de99f700.svg") center / cover no-repeat;
  width: 100%;
  height: 13vw;
  display: block;
  position: relative;
  top: 2px;
}

@media screen and (width >= 900px) {
  .about:after {
    background-image: url("shape5.499c4049.svg");
    height: 8vw;
  }
}

.footer {
  text-align: center;
  flex-direction: column;
  align-items: stretch;
  padding: .5rem 2rem;
  display: flex;
}

@media screen and (width >= 900px) {
  .footer {
    text-align: left;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.footer p {
  margin: .15rem 0;
  font-size: .875rem;
}

.footer a {
  color: #000;
}

.footer__logos {
  align-self: center;
  max-width: 8rem;
  margin-bottom: 1.5rem;
  display: flex;
}

@media screen and (width >= 900px) {
  .footer__logos {
    order: 1;
    max-width: 10rem;
    margin: 0;
  }

  .footer__info {
    text-align: right;
    order: 2;
  }
}

.footer__logo {
  width: 58px;
  min-width: 0;
  margin: .25rem;
  display: flex;
}

.footer__logo img {
  max-width: 100%;
}

@media screen and (width >= 900px) {
  .footer__logo {
    width: 109px;
  }
}

.footer a.footer__logo {
  width: 44px;
}

@media screen and (width >= 900px) {
  .footer a.footer__logo {
    width: 82px;
  }
}

.footer__contact {
  margin-bottom: 1.5rem;
}

@media screen and (width >= 900px) {
  .footer__contact {
    margin: 0;
  }
}
/*# sourceMappingURL=index.eb75e6e3.css.map */
