@use '../global';
.intro {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../assets/svg/shape1_mobile.svg');
  background-repeat: no-repeat;
  background-size: 100%;

  background-position: top center;
  min-height: 160vw;
  margin-top: 50vh;

  @media screen and (min-width: global.$breakpoint-s) {
    background-position: 50% 25%;
  }

  @media screen and (min-width: global.$breakpoint-l) {
    background-image: url('../assets/svg/shape1.svg');
    min-height: 70vw;
    margin-top: 25vh;

    background-size: cover;
    background-position: center;
  }
  @media screen and (min-width: global.$breakpoint-xl) {
    min-height: 60vw;
  }
}
