@use '../global';

.help {
  @include global.center-column;
  padding-top: 2rem;
}

.help::after {
  @include global.jagged-edge(
    34vw,
    '../assets/svg/mob-shape4.svg',
    '../assets/svg/shape4.svg',
    27.8vw
  );
}

.help__heading {
  padding-bottom: 2rem;
}

.help__buttons {
  text-align: center;
  padding: 0 1rem;
}
