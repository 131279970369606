@use '../global';

$button-skew: 2deg;
$button-skew-sm: 5deg;

.button:not(.button:last-child) {
  margin-right: 0.25rem;
}
.button:not(.button:first-child) {
  margin-left: 0.25rem;
}
.button {
  background-color: black;
  display: inline-block;
  border: none;
  text-transform: uppercase;
  transform: skewY($button-skew);
  font-size: 1.25rem;
  @include global.baton-bold;
  padding: 0.6rem 2.5rem;
  margin: 1rem 0;
  max-width: 95vw;
  color: global.$brand-yellow;
  cursor: pointer;
  transition: transform 0.4s ease-in-out;

  &:hover {
    transform: skewY($button-skew * -1);

    .button__text {
      transform: skewY($button-skew);
    }
  }

  &--straight {
    text-transform: uppercase;
    transform: none;
    font-size: 1rem;
    @include global.baton-turbo;
    padding: 1.5rem 1rem;
    margin: 0;
    max-width: 95vw;
    border-radius: global.$border-radius;
  }

  &--small {
    margin: 0;
    padding: 0.6rem;
    transform: skewY($button-skew-sm);
    @media screen and (min-width: global.$breakpoint-s) {
      padding: 1.25rem;
    }
  }

  &__text {
    display: inline-block;
    transform: skewY($button-skew * -1);
    transition: transform 0.4s ease-in-out;
  }
}

.button--small .button__text {
  transform: skewY($button-skew-sm * -1);
}
