@use '../global';

$heading-font-size: 2.8em;
$header-offset: 0.25;

.heading {
  text-transform: uppercase;
  @include global.baton-turbo-medium-italic;
  font-size: $heading-font-size;
}

.heading span {
  display: block;
}

.heading--small {
  @include global.baton-bold;
  font-style: normal;
  margin: 1rem;
  font-size: 1.5em;
  @media screen and (min-width: global.$breakpoint-l) {
    font-size: 1.125em;
  }
}

.heading--small-turbo {
  margin: 1rem 0;
  text-align: center;

  font-size: 1.5em;
  font-style: normal;
  @media screen and (min-width: global.$breakpoint-l) {
    font-size: 1.125em;
  }
}

.heading span:nth-child(2) {
  margin-left: $heading-font-size * $header-offset;
}

.heading span:nth-child(3) {
  margin-left: $heading-font-size * $header-offset * 2;
}
.heading--extreme h1:nth-child(2) {
  margin-left: $heading-font-size * $header-offset * 2;
}

.heading--extreme h1:nth-child(3) {
  margin-left: $heading-font-size * $header-offset * 4;
}
