@use '../global';

.header {
  z-index: 2;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (min-width: global.$breakpoint-m) {
    position: fixed;
  }
}

.header__buttons {
  transform: translateY(-18%);
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  opacity: 0;
  pointer-events: none;

  position: fixed;
  top: 0;
  right: 0;
  z-index: 2;

  @media screen and (min-width: global.$breakpoint-m) {
    position: relative;
  }
}

.header__circle {
  width: 2.25rem;
  margin: 0.5rem 0 0 1rem;
  display: none;

  @media screen and (min-width: global.$breakpoint-s) {
    margin: 0.5rem 0 0 global.$page-edge-padding;
  }

  @media screen and (min-width: global.$breakpoint-m) {
    display: block;
  }
}

.header__progress {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 4px;
  background-color: global.$brand-yellow;
  transform-origin: top left;
  transform: scaleX(0.01);
  animation: loading 8s;
  animation-fill-mode: forwards;
}

@keyframes loading {
  from {
    transform: scaleX(0.01);
  }

  to {
    transform: scaleX(0.8);
  }
}

@keyframes loaded {
  from {
    transform: scale(0.8, 1);
  }

  to {
    transform: scaleY(1, 0);
  }
}

@keyframes disappear {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
.header__progress.header__progress--loaded {
  // opacity: 0;
  animation: loaded 1s, disappear 1s 0.5s forwards;
}
