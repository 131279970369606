@use '../global';

.about {
  @include global.center-column;
  background-color: global.$brand-yellow;
}

.about::after {
  @include global.jagged-edge(
    13vw,
    '../assets/svg/mob-shape5.svg',
    '../assets/svg/shape5.svg',
    8vw
  );
}
