@font-face {
  font-family: 'Baton';
  src: url('../assets/fonts/BatonWeb-Bold.woff2') format('woff2'),
    url('../assets/fonts/BatonWeb-Bold.woff') format('woff');
  font-weight: bold;
}
@font-face {
  font-family: 'BatonTurbo';
  src: url('../assets/fonts/BatonTurboWeb-Regular.woff2') format('woff2'),
    url('../assets/fonts/BatonTurboWeb-Regular.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'BatonTurbo';
  src: url('../assets/fonts/BatonTurboWeb-Medium.woff2') format('woff2'),
    url('../assets/fonts/BatonTurboWeb-Medium.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'BatonTurbo';
  src: url('../assets/fonts/BatonTurboWeb-BoldItalic.woff2') format('woff2'),
    url('../assets/fonts/BatonTurboWeb-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'BatonTurbo';
  src: url('../assets/fonts/BatonTurboWeb-Bold.woff2') format('woff2'),
    url('../assets/fonts/BatonTurboWeb-Bold.woff') format('woff');
  font-weight: 700;
}
