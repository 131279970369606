@use '../global';

.footer {
  @include global.center-column;
  align-items: stretch;
  text-align: center;
  padding: 0.5rem global.$page-edge-padding 0.5rem global.$page-edge-padding;

  @media screen and (min-width: global.$breakpoint-l) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    text-align: left;
  }

  p {
    font-size: 0.875rem;
    margin: 0.15rem 0rem;
  }

  a {
    color: black;
  }

  &__logos {
    align-self: center;
    max-width: 8rem;
    display: flex;
    margin-bottom: 1.5rem;

    @media screen and (min-width: global.$breakpoint-l) {
      order: 1;
      margin: 0;
      max-width: 10rem;
    }
  }

  &__info {
    @media screen and (min-width: global.$breakpoint-l) {
      order: 2;
      text-align: right;
    }
  }

  &__logo {
    min-width: 0;
    margin: 0.25rem;
    display: flex;
    width: 58px;

    img {
      max-width: 100%;
    }

    @media screen and (min-width: global.$breakpoint-l) {
      width: 109px;
    }
  }

  a.footer__logo {
    width: 44px;
    @media screen and (min-width: global.$breakpoint-l) {
      width: 82px;
    }
  }

  &__contact {
    margin-bottom: 1.5rem;

    @media screen and (min-width: global.$breakpoint-l) {
      margin: 0;
    }
  }
}
