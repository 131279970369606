@use '../global';

.modal {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  display: none;
  border: none;
}

.modal.visible {
  display: block;
}

.modal__form.display-none {
  display: none;
}

.modal__container {
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal__heading {
  font-size: 11vw;
  @media screen and (min-width: global.$breakpoint-xs) {
    font-size: 2rem;
  }
  @media screen and (min-width: global.$breakpoint-xs) and (min-height: global.$breakpoint-h) {
    font-size: 2.8rem;
  }
}
.modal__inner {
  position: relative;
  z-index: 1;
  background-color: global.$brand-yellow;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  justify-items: center;
  padding: 1rem;
  border-radius: global.$border-radius;
  overflow: scroll;
}
.modal__overlay {
  width: 100%;
  height: 100%;
  background-color: black;
  position: fixed;
  opacity: 0.3;
}
.modal__form {
  @include global.center-column;
  margin: 2rem 0;
  width: 100%;
  max-width: 30rem;
  @include global.baton-turbo;
  text-transform: uppercase;
}

.modal__form label {
  @include global.visually-hidden;
}

.modal__form input,
.modal__form textarea {
  width: 100%;
  margin: 0.3rem 0;
  padding: 0 2rem;
  background-color: transparent;
  border: none;
  box-shadow: 0 0 0 1px black;
  border-radius: global.$border-radius;
  resize: none;
  cursor: pointer;
  // required because https://stackoverflow.com/a/18953366/10434847
  -webkit-appearance: none;

  @include global.baton-turbo;
}

.modal__form input {
  height: 65px;
}

.modal__form textarea {
  height: 111px;
}

.modal__form input:focus,
.modal__form textarea:focus {
  background-color: white;
  outline: none;
  box-shadow: 0 0 0 2px black;
}

.modal__form input.activated:invalid:not(:focus),
.modal__form textarea.activated:invalid:not(:focus) {
  background-color: white;
  box-shadow: 0 0 0 2px red;
  color: grey;
}

.modal__form input.activated:not(:focus)::placeholder,
.modal__form textarea.activated:not(:focus)::placeholder {
  color: red;
  opacity: 0.5;
}

.modal__form textarea {
  padding: 1rem 2rem;
  @media screen and (min-height: global.$breakpoint-h) {
    padding: 1rem 2rem;
  }
}

.modal__form input::placeholder,
.modal__form textarea::placeholder {
  color: black;
  opacity: 0.5;
}

.modal__submit {
  margin: 0;
  margin-top: 5%;

  @media screen and (min-height: global.$breakpoint-h) {
    margin: 2rem 0;
  }

  width: 100%;
}

.modal__close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  background-color: transparent;
  border: none;
  background: url('../assets/svg/close.svg') center / cover no-repeat;
  cursor: pointer;
}

.modal__error {
  font-size: 0.6rem;
  color: red;
  width: 100%;
  height: 0.6rem;
  margin: 0.3rem 0;
}

input:focus + span.modal__error {
  visibility: hidden;
}
.modal__thank-you {
  @include global.center-column;
  grid-row: span 2;
  justify-content: center;
  text-align: center;
}
.modal__done {
  width: 100%;
}
.modal__text {
  @include global.baton-turbo;
  text-transform: uppercase;
  margin: 2.5rem 0;
  font-size: 0.75rem;
}
