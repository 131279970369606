@use 'variables';

@mixin baton-bold {
  font-family: 'Baton', Arial, Helvetica, sans-serif;
  font-weight: bold;
  letter-spacing: 1px;
}

@mixin baton-turbo-medium-italic {
  font-family: 'BatonTurbo', Arial, Helvetica, sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  font-style: italic;
  line-height: 1;
}

@mixin baton-turbo {
  font-family: 'BatonTurbo', Arial, Helvetica, sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1;
}

@mixin center-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin text-padding {
  padding: 0 4%;
  @media screen and (min-width: variables.$breakpoint-m) {
    padding: 0 15%;
  }
  @media screen and (min-width: variables.$breakpoint-l) {
    padding: 0 20%;
  }
  @media screen and (min-width: variables.$breakpoint-xl) {
    padding: 0 30%;
  }
}

@mixin jagged-edge($height, $mobile, $desktop, $desktopHeight) {
  content: '';
  display: block;
  position: relative;
  width: 100%;
  height: $height;
  background: url($mobile) center / cover no-repeat;
  position: relative;
  top: 2px;

  @media screen and (min-width: variables.$breakpoint-l) {
    background-image: url($desktop);
    height: $desktopHeight;
  }
}

@mixin visually-hidden {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
